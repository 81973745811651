<template>
  <div class="website">
    <div class="weibsite-banner">
      <img src="@/assets/images/webSitebg.png" />
    </div>
    <div class="content1">
      <h3 class="hl-active">亿券会员权益发行系统</h3>
      <h3 class="content-h3">助企业打破传统销售，共享互联网红利</h3>
      <ul>
        <li v-for="(item, index) in dividendList" :key="index">
          <div><img :src="item.url" /></div>
          <div>{{ item.name1 }}</div>
          <p>{{ item.name2 }}</p>
          <p>{{ item.name3 }}</p>
          <p>{{ item.name4 }}</p>
        </li>
      </ul>
    </div>
    <div class="content2 bgactive">
      <h3 class="content-h3"><span class="yq-active">"亿券"</span>可帮助企业解决福利市场哪些核心问题</h3>
      <ul class="flexpub">
        <li v-for="(item, index) in coreList" :key="index">
          <img :src="item.url" />
        </li>
      </ul>
    </div>
    <div class="content-pub">
      <h3 class="h3-active">多维度组合产品</h3>
      <h3 class="content-h3">有效增加产品展示及销售渠道</h3>
      <ul class="flexpub">
        <li
          @mouseenter="mouseeFuc(index, 1)"
          @mouseleave="mouseleaveFun()"
          v-for="(item, index) in channelList1"
          :key="index"
        >
          <img
            :src="
              isExitHover && activeIndex1 === index ? item.hoverUrl : item.url
            "
          />
        </li>
      </ul>
    </div>
    <div class="content-pub bgactive">
      <h3 class="h3-active">提供专业、高效、定制化的</h3>
      <h3 class="content-h3">一站式解决方案助企业实现快速转型</h3>
      <ul class="flexpub">
        <li
          @mouseenter="mouseeFuc(index, 2)"
          @mouseleave="mouseleaveFun()"
          v-for="(item, index) in channelList2"
          :key="index"
        >
          <img
            :src="
              isExitHover && activeIndex2 === index ? item.hoverUrl : item.url
            "
          />
        </li>
      </ul>
    </div>
    <div class="content-pub">
      <h3 class="h3-active">全方位搭建运营系统</h3>
      <h3 class="content-h3">助企业科学制定经营决策及产品优化方案</h3>
      <ul class="flexpub">
        <li
          @mouseenter="mouseeFuc(index, 3)"
          @mouseleave="mouseleaveFun()"
          v-for="(item, index) in channelList3"
          :key="index"
        >
          <img
            :src="
              isExitHover && activeIndex3 === index ? item.hoverUrl : item.url
            "
          />
        </li>
      </ul>
    </div>
    <div class="content-pub bgactive">
      <h3 class="h3-active">专业标准化客服管理系统</h3>
      <h3 class="content-h3">全面、精准、快速满足售后需求</h3>
      <ul class="flexpub">
        <li
          @mouseenter="mouseeFuc(index, 4)"
          @mouseleave="mouseleaveFun()"
          v-for="(item, index) in channelList4"
          :key="index"
        >
          <img
            :src="
              isExitHover && activeIndex4 === index ? item.hoverUrl : item.url
            "
          />
        </li>
      </ul>
    </div>
    <div class="content-last">
      <h3 class="content-h3">适用行业</h3>
      <ul class="flexpub">
        <li v-for="(item, index) in industryList" :key="index">
          <img :src="item.url" />
          <div>{{ item.name1 }}</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { onMounted, reactive, toRefs, getCurrentInstance } from "vue";
export default {
  setup() {
    const { proxy } = getCurrentInstance();
    const data = reactive({
      dividendList: [
        {
          name1: "卡券预售，提前收款",
          name2: "提前销售卡券，后期按需发货及时",
          name3: "回款，提高资金周转率 ",
          url: require("@/assets/images/dividendIcon1.png"),
        },
        {
          name1: "大宗团购，应对自如",
          name2: "集中把控产品在市场上的价格，质量",
          name3: "数量，大量团购，也能应对自如",
          url: require("@/assets/images/dividendIcon2.png"),
        },
        {
          name1: "按需量产，成本可控",
          name2: "根据市场需求进行备货，无需提前",
          name3: "大量囤货，缓解资金周转压力，避",
          name4: "免市场无效预测",
          url: require("@/assets/images/dividendIcon3.png"),
        },
        {
          name1: "定制福利，一站式解决方案",
          name2: "包含产品设计及开发，网络营销，渠",
          name3: "道运营，福利发放一步到位，实现高",
          name4: "效工作",
          url: require("@/assets/images/dividendIcon4.png"),
        },
      ],
      coreList: [
        {
          id: 1,
          url: require("@/assets/images/coreIcon1.png"),
        },
        {
          id: 2,
          url: require("@/assets/images/coreIcon2.png"),
        },
        {
          id: 3,
          url: require("@/assets/images/coreIcon3.png"),
        },
        {
          id: 4,
          url: require("@/assets/images/coreIcon4.png"),
        },
      ],
      channelList1: [
        {
          id: 1,
          url: require("@/assets/images/channelIcon1.png"),
          hoverUrl: require("@/assets/images/channelIconhover1.png"),
        },
        {
          id: 2,
          url: require("@/assets/images/channelIcon2.png"),
          hoverUrl: require("@/assets/images/channelIconhover2.png"),
        },
        {
          id: 3,
          url: require("@/assets/images/channelIcon3.png"),
          hoverUrl: require("@/assets/images/channelIconhover3.png"),
        },
      ],
      channelList2: [
        {
          id: 1,
          url: require("@/assets/images/channelIcon4.png"),
          hoverUrl: require("@/assets/images/channelIconhover4.png"),
        },
        {
          id: 2,
          url: require("@/assets/images/channelIcon5.png"),
          hoverUrl: require("@/assets/images/channelIconhover5.png"),
        },
        {
          id: 3,
          url: require("@/assets/images/channelIcon6.png"),
          hoverUrl: require("@/assets/images/channelIconhover6.png"),
        },
      ],
      channelList3: [
        {
          id: 1,
          url: require("@/assets/images/channelIcon7.png"),
          hoverUrl: require("@/assets/images/channelIconhover7.png"),
        },
        {
          id: 2,
          url: require("@/assets/images/channelIcon8.png"),
          hoverUrl: require("@/assets/images/channelIconhover8.png"),
        },
        {
          id: 3,
          url: require("@/assets/images/channelIcon9.png"),
          hoverUrl: require("@/assets/images/channelIconhover9.png"),
        },
      ],
      channelList4: [
        {
          id: 1,
          url: require("@/assets/images/channelIcon10.png"),
          hoverUrl: require("@/assets/images/channelIconhover10.png"),
        },
        {
          id: 2,
          url: require("@/assets/images/channelIcon11.png"),
          hoverUrl: require("@/assets/images/channelIconhover11.png"),
        },
        {
          id: 3,
          url: require("@/assets/images/channelIcon12.png"),
          hoverUrl: require("@/assets/images/channelIconhover12.png"),
        },
      ],
      industryList: [
        {
          name1: "蔬菜",
          url: require("@/assets/images/industryIcon1.png"),
        },
        {
          name1: "水果",
          url: require("@/assets/images/industryIcon2.png"),
        },
        {
          name1: "茶叶",
          url: require("@/assets/images/industryIcon3.png"),
        },
        {
          name1: "零食",
          url: require("@/assets/images/industryIcon4.png"),
        },
        {
          name1: "电影",
          url: require("@/assets/images/industryIcon5.png"),
        },
        {
          name1: "家居",
          url: require("@/assets/images/industryIcon6.png"),
        },
        {
          name1: "数码",
          url: require("@/assets/images/industryIcon7.png"),
        },
        {
          name1: "节日礼包",
          url: require("@/assets/images/industryIcon8.png"),
        },
      ],
      isExitHover: false,
      activeIndex1: "",
      activeIndex2: "",
      activeIndex3: "",
      activeIndex4: "",
    });

    // const JumpSearch = () =>{
    //   proxy.$router.push({
    //     path: "/sites/search",
    //     query: {
    //       id: data.productId,
    //     },
    //   });
    // }
    const mouseeFuc = (index, tip) => {
      switch (tip) {
        case 1:
          data.activeIndex1 = index;
          break;
        case 2:
          data.activeIndex2 = index;
          break;
        case 3:
          data.activeIndex3 = index;
          break;
        case 4:
          data.activeIndex4 = index;
          break;
        default:
          data.activeIndex1 = "";
          data.activeIndex2 = "";
          data.activeIndex3 = "";
          data.activeIndex4 = "";
      }
      data.isExitHover = true;
    };

    const mouseleaveFun = () => {
      data.activeIndex1 = "";
      data.activeIndex2 = "";
      data.activeIndex3 = "";
      data.activeIndex4 = "";
      data.isExitHover = false;
    };

    // 页面加载时调用
    onMounted(() => {});
    return {
      mouseeFuc,
      mouseleaveFun,
      ...toRefs(data),
    };
  },
};
</script>
<style lang='scss' scoped>
$primary-color: #2b2b2b;
$primary-size: 50px;
.website {
  .weibsite-banner {
    height: 774px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content1 {
    padding-top: 79px;
    padding-bottom: 40px;
    ul {
      display: flex;
      width: 1522px;
      margin: 0 auto;
      justify-content: space-between;

      margin-top: 152px;
      li {
        div {
          &:first-of-type {
            height: 180px;
            margin-bottom: 24px;
            box-sizing: border-box;
          }
          &:last-of-type {
            color: $primary-color;
            font-size: 26px;
            margin-bottom: 28px;
          }
        }
        &:first-of-type {
          div {
            &:first-of-type {
              padding-top: 10px;
              img {
                width: 160px;
                height: 160px;
              }
            }
          }
        }
        &:nth-of-type(2) {
          div {
            &:first-of-type {
              padding-top: 10px;
              img {
                width: 160px;
                height: 160px;
              }
            }
          }
        }
        &:nth-of-type(3) {
          div {
            &:first-of-type {
              img {
                width: 180px;
                height: 180px;
              }
            }
          }
        }
        &:nth-of-type(4) {
          div {
            &:first-of-type {
              padding-top: 16.5px;
              img {
                width: 166px;
                height: 143px;
              }
            }
          }
        }
        p {
          color: #787878;
          font-size: 20px;
          text-align: left;
          margin-top: 10px;
          &:first-of-type {
            margin-top: 0px;
          }
        }
      }
    }
  }
  .content2 {
    padding: 60px 0px;
    ul {
      width: 1657px;
      margin-top: 122px;
      li {
        img {
          width: 380px;
          height: 365px;
        }
      }
    }
  }
  .content-pub {
    padding: 100px 0px;
    ul {
      width: 1333px;
      margin-top: 115px;
      li {
        img {
          width: 412px;
          height: 383px;
        }
      }
    }
  }
  .content-last {
    padding: 60px 0px 120px 0px;
    ul {
      flex-wrap: wrap;
      width: 1336px;
      margin-top: 100px;
      li {
        margin-top: 30px;
        div {
          font-size: 30px;
          color: $primary-color;
          margin-top: 5px;
        }
        img {
          width: 304px;
          height: 304px;
        }
      }
    }
  }
}
.content-h3 {
  color: $primary-color;
  font-size: $primary-size;
  position: relative;
  
  &::after {
    content: "";
    width: 237px;
    height: 6px;
    background: #808080;
    position: absolute;
    left: 50%;
    margin-left: -118.5px;
    bottom: -30px;
  }
}
.hl-active{
    color: $primary-color;
  font-size: 52px;
  // font-weight: bold;
  }
.h3-active {
  color: $primary-color;
  font-size: $primary-size;
}
.bgactive {
  background: #efefef;
}
.flexpub {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.yq-active{
  // font-weight: bold;
  font-size: 52px;
}
</style>